import LinkField from 'components/LinkField';
import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  SimpleShowLayout,
  FunctionField,
  Pagination,
} from 'react-admin';
import { formatDate } from 'utils/date';

const SkillsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      label={false}
      pagination={<Pagination />}
      reference="ProfessionalQualification"
      filter={{ search: { qualification_types: ['typeform', 'external_skills_assessment'] } }}
      target="professional_id"
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-skills_tab_table">
        <LinkField resource="ProfessionalQualification" source="id" />
        <TextField
          label="Skills Assessment"
          source="qualification.long_name"
          data-testid="professional_show-skills_tab_skills_assessment"
        />
        <FunctionField
          label="Expiration Date"
          render={(v) =>
            formatDate(
              v?.professional_qualification_attributes?.find(({ type }) =>
                type.includes('ExpirationDate'),
              )?.value,
            )
          }
          data-testid="professional_show-skills_tab_expiration_date"
        />
        <FunctionField
          label="Date Taken"
          render={(v) => {
            const dateTaken = v?.professional_qualification_attributes?.find(
              (pqa) => pqa.qualification_attribute?.name === 'Date Taken',
            );
            return formatDate(dateTaken?.value);
          }}
        />
        <FunctionField
          label="Created At Date"
          render={(v) => formatDate(v?.created_at)}
          data-testid="professional_show-skills_tab_created_at_date"
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default SkillsTab;
