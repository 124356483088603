import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  SelectInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from 'react-admin';
import PolymorphicField from 'components/PolymorphicField';
import EnumField from 'components/Enum/EnumField';
import List from 'components/List';
import PolymorphicInput from 'components/PolymorphicInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import ReferenceInput from 'components/ReferenceInput';
import IdField from 'components/IdField';
import DateFilter from 'components/Filter/DateFilter';
import { billingPlanOptions } from './utils';

const Filters = [
  <EnumSelectInput alwaysOn resettable source="kind" />,
  <PolymorphicInput
    alwaysOn
    resettable
    field="requester"
    choices={[
      { id: 'Location', name: 'Location' },
      { id: 'Professional', name: 'Professional' },
    ]}
  />,
  <PolymorphicInput
    alwaysOn
    field="requestee"
    choices={[
      { id: 'Location', name: 'Location' },
      { id: 'Professional', name: 'Professional' },
    ]}
  />,
  <ReferenceInput
    label="Location Block Reason"
    alwaysOn
    emptyText="All"
    perPage={50}
    sort={{ field: 'label', order: 'ASC' }}
    reference="LocationBlockReason"
    source="search.location_block_reason_id"
  >
    <SelectInput optionText="label" label="Location Block Reason" />
  </ReferenceInput>,
  <ReferenceInput
    label="Professional Block Reason"
    alwaysOn
    emptyText="All"
    perPage={50}
    sort={{ field: 'label', order: 'ASC' }}
    reference="ProfessionalBlockReason"
    source="search.professional_block_reason_id"
  >
    <SelectInput optionText="label" label="Professional Block Reason" />
  </ReferenceInput>,
  <DateFilter alwaysOn label="From Date" source="search.from_date" />,
  <DateFilter alwaysOn label="To Date" source="search.to_date" />,
  <EnumSelectInput alwaysOn resettable source="professional_type" />,
  <SelectInput
    alwaysOn
    choices={billingPlanOptions}
    label="Billing Type"
    source="search.billing_type"
  />,
];

const RelationshipsList = () => (
  <List filters={Filters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <EnumField label="Kind" source="kind" />
      <TextField label="Comments" source="comments" />
      <PolymorphicField linkType={false} source="reason" render={(v) => v.label} label="Reason" />
      <PolymorphicField source="requester" label="Requester" />
      <PolymorphicField source="requestee" label="Requestee" />
      <FunctionField
        label="Billing Type"
        render={(v) => {
          const location = v?.requestee?.__typename === 'Location' ? v.requestee : v.requester;
          if (!location?.primary_billing_group?.plan) {
            return;
          }
          if (location?.primary_billing_group.plan === 'enterprise_w2') {
            return 'W2';
          } else if (location?.primary_billing_group.plan === 'standard') {
            return '1099';
          }
        }}
      />
      <FunctionField
        label="Professional Type"
        render={(v) => {
          const professional =
            v?.requestee?.__typename === 'Professional' ? v.requestee : v.requester;
          if (!professional) {
            return;
          }
          if (professional?.account?.company_id) {
            return 'Irp';
          } else if (professional?.account?.agency_id) {
            return 'Agency';
          }
          return 'Marketplace';
        }}
      />
      <ArrayField label="Requestee Positions" source="requestee.positions">
        <SingleFieldList linkType={false}>
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

RelationshipsList.query = gql`
  query GET_LIST($input: RelationshipQueryInput) {
    _relationshipsMeta(input: $input) {
      count
    }
    relationships(input: $input) {
      id
      kind
      comments
      requester {
        ... on Location {
          primary_billing_group {
            plan
          }
          id
          name
          __typename
        }
        ... on Professional {
          display_name: name
          id
          account {
            company_id
            agency_id
          }
          __typename
        }
      }
      requestee {
        ... on Location {
          primary_billing_group {
            plan
          }
          id
          name
          __typename
        }
        ... on Professional {
          display_name: name
          id
          account {
            company_id
            agency_id
          }
          positions {
            id
            display_name
          }
          __typename
        }
      }
      reason {
        ... on LocationBlockReason {
          id
          label
          __typename
        }
        ... on ProfessionalBlockReason {
          id
          label
          __typename
        }
      }
      created_at
    }
  }
`;

export default RelationshipsList;
