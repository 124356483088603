import React from 'react';
import { Button } from '@mui/material';
import {
  TopToolbar,
  List as RAList,
  FilterButton,
  useListContext,
  useResourceDefinition,
  CreateButton,
  ExportButton,
  SavedQueriesList,
  StoreContextProvider,
  localStorageStore,
} from 'react-admin';

export const ListActions = (props) => {
  const { exporter, setFilters, displayedFilters, filterValues } = useListContext(props);
  const { hasCreate } = useResourceDefinition(props);
  const hasFilters =
    Object.keys(displayedFilters).length > 0 || Object.keys(filterValues).length > 0;

  return (
    <TopToolbar>
      {exporter !== false && <ExportButton />}
      {hasFilters && (
        <Button
          color="primary"
          size="small"
          onClick={() => setFilters({}, {})}
          data-testid="clear-filters-button"
        >
          Clear Filters
        </Button>
      )}
      {props.filters !== undefined && <FilterButton data-testid="filter-button" />}
      {hasCreate && <CreateButton data-testid="create-button" />}
      {props.children}
    </TopToolbar>
  );
};

const List = (props) => {
  const { children, additionalActions, ...rest } = props;
  const store = localStorageStore();
  const displaySavedQueries = Boolean(rest.filters?.length);

  return (
    <RAList
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions {...rest}>{additionalActions}</ListActions>}
      {...rest}
    >
      <StoreContextProvider value={store}>
        {displaySavedQueries && <SavedQueriesList />}
      </StoreContextProvider>
      {children}
    </RAList>
  );
};

export default List;
