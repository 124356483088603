import React from 'react';
import { FunctionField, SimpleShowLayout, TextField } from 'react-admin';
import StateField from 'components/StateField';
import TimestampFields from 'components/TimestampFields';

const InfoTab = () => {
  return (
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField label="Legal Name" source="legal_name" />
      <TextField label="Status" source="status" />
      <TextField label="Website" source="website" />
      <FunctionField
        label="Address"
        render={(record) =>
          [record?.address_first, record?.address_second].filter(Boolean).join(', ')
        }
      />
      <TextField label="City" source="address_city" />
      <StateField />
      <TextField label="Zip Code" source="address_zip" />
      <TextField label="Phone Number" source="phone_number" />
      <FunctionField
        label="Job Types"
        render={(record) => record.job_types?.map((job_type) => job_type).join(', ')}
      />
      <TimestampFields />
    </SimpleShowLayout>
  );
};

export default InfoTab;
