import * as React from 'react';
import {
  TextField,
  Datagrid,
  ChipField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';

const JobsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Job"
      target="agency_id"
      sort={{ field: 'starts_date', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false} data-testid="professional_show-jobs_tab_table">
        <IdField label="ID" source="id" />
        <TextField
          label="Location"
          source="location.name"
          data-testid="professional_show-jobs_tab_location"
          sortBy="location_name"
        />
        <ArrayField label="Positions" source="positions" sortable={false}>
          <SingleFieldList linkType={false} data-testid="professional_show-jobs_tab_positions">
            <ChipField source="display_name" />
          </SingleFieldList>
        </ArrayField>
        <DateTimeField label="Starts Date" format="MM/dd/yyyy" source="starts_date" />
        <FunctionField
          label="Starts Time"
          render={(record) => {
            if (record.shift_type === 'call_back') {
              return (
                <DateTimeField
                  source="callback_starts_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else if (record.shift_type === 'on_call') {
              return (
                <DateTimeField
                  source="on_call_starts_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else {
              return (
                <DateTimeField
                  source="starts_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            }
          }}
        />
        <FunctionField
          label="Ends Time"
          render={(record) => {
            if (record.shift_type === 'call_back') {
              return (
                <DateTimeField
                  source="callback_ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else if (record.shift_type === 'on_call') {
              return (
                <DateTimeField
                  source="on_call_ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else {
              return (
                <DateTimeField
                  source="ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            }
          }}
        />
        <FunctionField
          label="Shift Type"
          render={() => <TextField source="shift_type" />}
          data-testid="professional_show-jobs_tab_shift_type"
        />
        <FunctionField label="Tax Type" render={(record) => (record.is_w2 ? 'W2' : '1099')} />
        <TextField label="Status" source="status" data-testid="professional_show-jobs_tab_status" />
        <LinkField label="Location ID" source="location_id" />
        <LinkField
          label="Assignment ID"
          source="assignment_id"
          dataTestId="professional_show-jobs_tab_assignment_id"
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default JobsTab;
