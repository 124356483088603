import React from 'react';
import { Edit, TextInput, SimpleForm, SelectArrayInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PhoneInput from 'components/PhoneInput';
import StateInput from 'components/StateInput';

const AgenciesEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <TextInput label="Name" source="name" />
        <TextInput label="Legal Name" source="legal_name" />
        <EnumSelectInput label="Status" source="status" />
        <TextInput label="Website" source="website" />
        <TextInput label="Address1" source="address_first" />
        <TextInput label="Address2" source="address_second" />
        <TextInput label="City" source="address_city" />
        <StateInput />
        <TextInput label="Zip Code" source="address_zip" />
        <PhoneInput label="Phone Number" source="phone_number" />
        <SelectArrayInput
          label="Job Types"
          source="job_types"
          choices={[
            { id: 'per_diem', name: 'Per Diem' },
            { id: 'permanent_placement', name: 'Permanent Placement' },
            { id: 'travel', name: 'Travel' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default AgenciesEdit;
